<template>
  <div class="flex flex-col items-stretch bg-body min-h-screen bg-theme-bg">
    <Navbar type="limited" />
    <div class="flex items-stretch w-full min-h-screen">
      <div class="contents">
        <div class="inner-wrapper">
          <router-view />
        </div>
      </div>
    </div>
    <Footer :isLimited="true" />
  </div>
</template>

<script>
import Navbar from "./modules/Navbar.vue";
import Footer from "./modules/Footer.vue";
export default {
  name: "NavbarLayout",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.contents {
  @apply block h-full flex-grow pt-12 mx-auto;
  @include maxContainer();

  .inner-wrapper {
    @apply px-8 py-12;
  }
}
</style>
